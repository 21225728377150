@media screen and (min-width: 1200px) and (max-width: 1299px) {}


@media screen and (min-width: 992px) and (max-width: 1199px) {
    .create-pass-btn-main .primary-btn {
        margin-top: 25px;
    }
}


/* large screeen */
@media screen and (max-width:991px) {}

/* tablet screen */
@media screen and (min-width:768px) and (max-width:991px) {
    .set-select .ant-cascader-dropdown .ant-cascader-menu {
        min-width: 121px !IMPORTANT;
    }
    
    li.ant-cascader-menu-item {
        width: 100% !important;
    }
    
    .ant-cascader-menu-item {
        width: 100px;
    }

    .login-left {
        display: none;
    }

    .bsetu-datail-res {
        display: block;
        margin-bottom: 15px;
    }

    .bsetu-datail-res .bsetu-datail-para {
        color: #464646;
        font-size: 13px;
        font-family: 'Poppins-Medium';
        margin-bottom: 20px;
    }

    .login-right {
        /* background: linear-gradient(99deg, rgba(2, 0, 36, 35%) 0%, rgb(154 106 58 / 29%) 0%, rgb(116 96 70 / 35%) 30%, rgb(82 87 81 / 46%) 57%, rgb(45, 84, 108, 50%) 100%); */
        background-color: #fdfdfd;
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        height: 100vh;
        margin: 0;
    }

    .left-login-img-respo {
        display: block;
        width: 160px;
        height: 160px;
        margin: auto;
        margin-bottom: 30px;
    }

    .right-detail h5 {
        font-size: 29px;
        margin-bottom: 20px;
    }

    .set-reg-box {
        margin: 10px 0 !important;
    }

    .right-login-btn {
        margin-top: 15px;
    }

    /* otp page */
    .right-detail h6 {
        text-align: center;
    }

    .submit-btn {
        line-height: 28px;
    }

    .text-account-line span,
    .text-account-line a {
        font-family: "Poppins-Regular";
        color: #464646;
    }

    .text-account-line a {
        color: #074369;
    }

    .login-main {
        flex-direction: column;
    }

    .login-left-main {
        display: none;
    }

    .login-right-main {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .auth-inner-main {
        padding: 15px;
    }

    .auth-inner-main::-webkit-scrollbar {
        width: 0px;
    }

    .login-logo {
        height: 70px;
        width: 100px;
    }

    .auth-logo-box {
        margin-bottom: 10px;
    }

    .auth-inner-main .auth-page-titles {
        margin-top: 20px;
    }



    .companytype-chechbox .MuiFormControlLabel-root {
        margin-bottom: 12px;
    }

    .cust-grid-column-5 {
        width: 40%;
        max-width: 40%;
        flex: 0 0 40%;
    }

    .cust-grid-column-1 {
        width: 10%;
        max-width: 10%;
        flex: 0 0 10%;
    }

    .create-pass-btn-main .primary-btn {
        margin-top: 7px;
    }
}
























/* mobile screen */
@media screen and (max-width:767px) {
    .auth-input-main2 .ant-select:hover  .ant-select-selector {
        border-color:rgb(33 33 33) !important;
        box-shadow: none !important;
      }
   
.auth-input-main2 .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: #626262;
    background:transparent !important;
}
.set-select span.ant-select-selection-placeholder,
.auth-input-main2 .ant-select-selector{
    background:whitesmoke !important;
    padding-right: 33px !important;
 
}

    
.auth-input-main2 .ant-select-selector .ant-select-selection-item{
    display:flex !important;
    align-items:center !important;
    color: #666;
    font-family: Poppins-Regular;
    font-size: 15px;
    height: auto;
    line-height: 20px;
     padding: 0 5px! important; 
     width: 100%;
    }

    .set-select .ant-select-dropdown.ant-cascader-dropdown {
        min-width: 50%;
        right: 30px;
        overflow: auto;
        height: 265px;
    }

    .set-select .ant-cascader-dropdown .ant-cascader-menus .ant-cascader-menu{
        min-width: 100%;
    }

    .set-select .ant-cascader-dropdown .ant-cascader-menus:first-child .ant-cascader-menu{
       height: initial!important;
    }

    .set-select .ant-cascader-dropdown .ant-cascader-menu,
    .set-select:where(.css-dev-only-do-not-override-1km3mtt).ant-cascader-dropdown .ant-cascader-menu {
        border-bottom: 1px solid #074369;
    }
    :where(.css-dev-only-do-not-override-ixblex).ant-select-single.ant-select-show-arrow .ant-select-selection-item, :where(.css-dev-only-do-not-override-ixblex).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-inline-end: 18px;
        margin-top: 6px;
        color: #626262;
    }
    
    .set-select .ant-cascader-menus {
        flex-wrap: wrap;
    }

    .login-left {
        display: none;
    }

    .bsetu-datail-res {
        display: block;
        margin-bottom: 15px;
    }

    .bsetu-datail-res .bsetu-datail-para {
        color: #464646;
        font-size: 10px;
        display: none;
    }

    .desk-text {
        display: none;
    }

    .login-right {
        /* background: linear-gradient(99deg, rgba(2, 0, 36, 35%) 0%, rgb(154 106 58 / 29%) 0%, rgb(116 96 70 / 35%) 30%, rgb(82 87 81 / 46%) 57%, rgb(45, 84, 108, 50%) 100%); */
        background-color: #fdfdfd;
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        min-height: 100vh;
        margin: 0;
    }

    .left-login-img-respo {
        display: block;
        width: 68px;
        height: 59px;
        margin: auto;
        margin-bottom: 25px;
        margin-top: 8px;
    }

    .right-detail h5 {
        font-size: 25px;
        margin-top: 16px;
    }

    .register-as {
        margin-left: 0px;
        margin-top: 18px;
        margin-bottom: 0;
    }

    .right-login-btn {
        margin-top: 20px;
    }

    .signup-detail {
        padding: 20px 15px;
    }

    .register-as label {
        margin: 0;
        width: 100%;
        display: flex;
    }

    .register-as .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
    }

    .register-as label span {
        padding: 0;
    }

    .register-as label .MuiFormControlLabel-label {
        font-size: 14px;
    }

    .set-reg-box {
        width: 100%;
        margin-top: 5px !important;
    }

    .set-reg-box .MuiFormLabel-colorPrimary {
        margin-top: 0;
        text-align: start;
    }

    .register-both-check {
        margin-top: 10px;
    }

    .register-both-check .register-check {
        padding: 0 0;
        height: 36px;
        width: 155px;
    }

    /* otp page */
    .right-detail h6 {
        font-size: 18px;
        text-align: center;
    }

    .text-account-line span,
    .text-account-line a {
        font-family: "Poppins-Regular";
        color: #464646;
    }

    .text-account-line a {
        color: #074369;
    }

    .right-detail {
        padding: 0 15px 15px;
    }

    .personal-pro-btn1 .add-more-btn {
        min-width: 120px;
    }

    .upload-bttn .bttn-clr {
        max-width: 173px;
        margin-bottom: 15px;
    }

    .submit-btn {
        min-width: 120px;
    }

    .profile-page-upload .upload-resum-text {
        font-size: 15px;
        line-height: 17px;
        padding-top: 8px;
    }

    .personal-type .work-as-titles {
        font-size: 15px;
        line-height: 17px;
    }

    .personal-ex .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        height: 43px;
        max-width: 150px;
    }

    .personal-ex {
        max-width: 100%;
    }

    .remove-btn {
        right: 0px;
        height: 20px !important;
        width: 20px !important;
    }

    .exep {
        justify-content: initial;
    }

    .login-bg {
        background-color: #f5f5f5;
        padding: 25px 15px;
        border-radius: 10px;
    }


    .condition-main .link-decorations {
        font-size: 10px;
        line-height: 14px;
    }

    .condition-span {
        font-size: 10px;
        line-height: 14px;
    }

    .find-job-img {
        display: none;
    }

    .login-bg-img {
        width: 100%;
        object-fit: contain;
    }

    .login-mobile-bg {
        margin-left: -15px;
        margin-right: -15px;
        position: relative;
        display: flex;
    }

    .login-mobile-bg .login-titles {
        font-size: 17px;
        line-height: 20px;
        font-family: "Poppins-semibold";
        color: #fff;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .login-mobile-bg .login-para {
        font-size: 10px;
        line-height: 15px;
        font-family: "Poppins-Regular";
        color: #fff;
    }

    .login-up-text-box {
        height: 100%;
        position: absolute;
        bottom: 0px;
        padding: 20px;
        top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-image: linear-gradient(0deg, #00000099, #0000007d, transparent);
    }


    .login-main {
        flex-direction: column;
    }

    .login-left-main {
        display: none;
    }

    .login-right-main {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .auth-inner-main::-webkit-scrollbar {
        width: 0px;
    }

    .login-logo {
        height: 70px;
        width: 100px;
    }

    .auth-logo-box {
        margin-bottom: 10px;
    }

    .auth-inner-main .auth-page-titles {
        margin-top: 20px;
    }

    .grid-row {
        margin-right: 0px;
        margin-left: 0px;
    }

    .companytype-chechbox .MuiFormControlLabel-root {
        margin-bottom: 10px;
    }

    .cust-grid-column-5 {
        width: 43%;
        max-width: 43%;
        flex: 0 0 43%;
    }

    .cust-grid-column-1 {
        width: 10%;
        max-width: 10%;
        flex: 0 0 10%;
    }

    .profile-page-upload .upload-bttn {
        flex-direction: column;
        align-items: flex-start;
    }

    .inner-baner-container .inner-title {
        font-size: 23px;
    }

    .auth-inner {
        padding: 15px;
    }

    .auth-inner .auth-para {
        font-size: 16px;
        line-height: 25px;
    }

    .grid-column {
        padding-right: 0px;
        padding-left: 0px;
    }

    .auth-inner .page-heading-main {
        margin-bottom: 27px;
        font-size: 21px;
        line-height: 26px;
    }

    .profile-page-upload .auth-sub-titles {
        font-size: 15px;
        line-height: 18px;
    }

    .change-pw-model {
        width: 350px;
        padding: 20px !important;
    }

    .product-upload-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .create-pass-btn-main .primary-btn {
        margin-top: 30px;
    }

    .create-pass-btn-main .primary-btn {
        margin-top: 25px;
    }

    .personal-profile-upload {
        margin: 20px 0px 0px;
    }

    /* expertise res start */
    .expertise-btn-listing .expertise-btn-list-inner .btn-list-main-exper .expertise-btn-box {
        line-height: 16px;
        font-size: 10px;
        padding: 2px 11px;
    }
    .expertise-btn-listing .expertise-btn-list-inner .btn-list-main-exper {
        padding: 0px 6px;
        margin-bottom: 12px;
    }
    .expertise-btn-listing .expertise-btn-list-inner {
        margin-left: -6px;
        margin-right: -6px;
    }
    .expertise-modal-main .modal-inner-list .modal-body-pd {
        padding: 17px 15px 10px 15px;
        overflow: auto;
    }
    
    .expertise-modal-main .modal-inner-list {
        max-width: 351px;
    }
    .expertise-modal-main .select-title-experties {
        font-size: 18px;
        margin-bottom: 10.5px;
    }
}


@media screen and (min-width: 320px) and (max-width:374px) {
    .expertise-modal-main .modal-inner-list {
        max-width: 300px;
    }
    .expertise-btn-listing .expertise-btn-list-inner .btn-list-main-exper .expertise-btn-box {
        padding: 7px 14px;
    }
}