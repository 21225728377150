@font-face {
  font-family: "Poppins-Medium";
  src: url(../fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Poppins-semibold";
  src: url(../fonts/Poppins-SemiBold.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
.container{
  max-width: 1140px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
}
.both-main {
  width: 100%;
  /* height: 100vh; */
  max-width: 100%;
  flex: 0 0 100%;
  display: flex;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px;
  /* align-items: center; */
}

/* .login-left {
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    max-width: 62%;
    flex: 0 0 62%;
} */

.login-left {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100vh;
  max-width: 50%;
  flex: 0 0 50%;
}

/* .login-right {
    width: 100%;
    max-width: 38%;
    flex: 0 0 38%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.login-right {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-logo-signup .signup-left {
  position: fixed;
}

.register-as {
  margin-top: 5px;
  margin-bottom: 10px;
}

/* type=number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.signup-right {
  position: absolute;
  right: 0;
  height: auto;
}

.labels-login .Remember-text .MuiTypography-body1 {
  font-size: 15px;
  font-family: "Poppins-Regular";
  color: #464646;
}

.login-left .left-login-img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.right-detail {
  padding: 0 40px;
  width: 100%;
  /* width: 80%; */
  /* text-align: center; */
  justify-content: center;
  margin: auto;
}

.signup-detail {
  padding: 50px 40px;
}

.mail-input-profile {
  width: 100%;
  font-size: 15px;
}

.profile-input-main .mail-input-profile:first-child {
  margin-top: 20px;
}

.mail-input {
  width: 100%;
  font-size: 15px;
  margin-bottom: 15px !important;
}

.right-detail h5 {
  margin: 0 0 15px;
  font-size: 25px;
  font-family: "Poppins-semibold";
  color: #464646;
}

.mail-input .inputbar-mail {
  padding: 8px 0;
}

.right-detail .pw-input {
  width: 100%;
  margin: 0;
}

.labels-login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.labels-login .login-forgot {
  text-decoration: none;
  color: #074267;
  font-size: 15px;
  font-family: "Poppins-Bold";
}

.right-login-btn {
  /* margin-top: 10px; */
  margin-top: 20px;
}

#demo-row-radio-buttons-group-label {
  color: #666666;
}

.register-text {
  margin-bottom: 10px;
}

.register-both-check .register-check {
  margin: 0 5px;
  background: #f3f3f3;
  padding: 0 14px;
  border-radius: 8px;
}

.right-detail .login-btn,
.right-detail .login-btn:hover {
  background-color: #074369;
  color: #fff;
  min-width: 170px;
  min-height: 43px;
  font-size: 15px;
  border-color: transparent;
  border-radius: 5px;
  font-family: "Poppins-Regular";
  cursor: pointer;
}

.mail-input .MuiInputBase-colorPrimary,
.pw-input .MuiInputBase-colorPrimary {
  height: 43px;
}

/* .mail-input .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.pw-input .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -6px !important;
} */
.auth-inner .page-heading-main {
  text-align: center;
  margin-bottom: 33px;
  color: #074369;
  font-size: 30px;
  line-height: 35px;
  font-family: 'Poppins-Medium';
}
.auth-inner .auth-para {
  font-size: 20px;
  line-height: 25px;
  font-family: 'Poppins-Regular';
  margin-bottom: 20px;
}

.create-pass-inner {
  padding: 50px;
}
.text-left{
  text-align: left !important;
}
.mail-input div .MuiFormLabel-filled,
.pw-input .MuiFormLabel-filled {
  top: 0;
}

.mail-input .MuiFormLabel-filled,
.pw-input .MuiFormLabel-filled {
  top: 0;
}

.mail-input div .Mui-focused,
.pw-input .Mui-focused {
  top: 0;
}
.mail-input .Mui-focused,
.pw-input .Mui-focused {
  top: 0;
}

.personal-profile-degree div label,
.pw-input label {
  top: -6px;
}

.personal-profile-degree input {
  height: auto !important;
  padding: 0px !important;
}

.mail-input div label,
.pw-input label {
  top: -6px;
}

.mail-input label,
.pw-input label {
  top: -6px;
}

.mail-input .MuiInputBase-input,
.pw-input .MuiInputBase-input {
  height: 43px;
  padding: 0 15px;
}

.right-detail .left-input {
  margin-right: 15px;
}

/* personal profile start */

.both-main .com-profile {
  margin-top: 16px;
}

.input-same {
  width: 49.25%;
}

.right-detail:nth-child("odd") .input-same {
  margin-right: 15px !important;
}

.personal-pro-btn {
  /* margin-top: 15px; */
  margin-top: 20px;
}

.personal-ex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.degree-width {
  width: 100% !important;
}

/* .degree-width .MuiAutocomplete-input {
  height: auto;
}

.qualification-data .MuiAutocomplete-input {
  height: auto;
}

.personal-ex .MuiAutocomplete-input {
  height: auto;
} */
.auth-input-main .MuiFormControl-root {
  width: 100%;
}
.auth-input-main input{
  height: auto;
}
.exep {
  margin-top: 5px;
  justify-content: space-between;
}

.personal-ex .text-width {
  width: 100%;
  max-width: 100%;
}

.sidebar-link {
  text-decoration: none;
  color: #074267;
  font-size: 15px;
  font-family: "Poppins-Regular";
}

.sidebar-link li .MuiListItemButton-gutters .MuiSvgIcon-fontSizeMedium {
  color: #074267;
}

.Expertise-text {
  margin-right: 25px !important;
}

.personal-pro-btn1 .add-more-btn {
  background-color: #074369;
  color: #fff;
  /* min-width: 150px; */
  min-width: 160px;
  min-height: 39px;
  font-size: 15px;
  border-color: transparent;
  border-radius: 5px;
  font-family: "Poppins-Regular";
  cursor: pointer;
}
.add-more-btn-disabled{
  background-color: #07436993 !important;

}

.personal-pro-btn1 {
  margin: 8px 0px 25px;
}

.curr-work {
  display: flex;
  flex-direction: column;
}

.curr-work .current-work-text {
  /* margin: 20px 0 10px; */
  margin: 20px 0 20px;
  font-family: "Poppins-Regular";
}

.profile-page {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
  /* margin-top: 10px; */
}

.curr-work .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #c4c4c4 !important;
}

.cur-work-input {
  height: 43px;
}

.helper-textt {
  display: flex;
  justify-content: space-between;
}

.personal-profile-degree {
  margin-top: 0px;
}

.profile-page-upload {
  margin: 15px 0;
}

.profile-continue {
  margin-top: 20px;
}
.resume-btn {
  margin: 0 0 30px 0;
}

.upload-bttn {
  margin-top: 5px;
  margin-bottom: 15px;
}

.upload-bttn .bttn-clr {
  border-radius: 5px;
  background-color: #074369;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  font-family: "Poppins-Regular";
  max-width: 170px;
  height: 40px;
  width: 100%;
}

.upload-bttn .bttn-clr.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #074369;
}

/* personal profile end */

/* header */
.main-user-select .logout-link {
  display: flex;
  color: #074267;
}

.head-menu svg {
  padding-right: 6px;
}

.panel-right {
  padding: 0px !important;
}

.drawer-main .panel-right {
  margin: 0;
}

.main-login .profile-same {
  height: 100%;
  align-items: baseline;
  min-height: 490px;
  border-radius: 25px;
}

.profile-next .login-btn {
  width: 100%;
  /* max-width: 210px; */
  max-width: 160px;
}

.curr-work .MuiInputBase-colorPrimary {
  border-radius: 5px;
  height: 43px;
}

.main-dashboard .MuiAppBar-colorPrimary {
  background-color: #074369;
}

.main-dashboard .MuiToolbar-regular {
  justify-content: flex-end;
}

.header-right-menu {
  width: 100%;
  text-align: end;
}

.header-right-menu .btn-dropdown {
  color: #ffffff;
}

.header-right-menu .btn-dropdown svg {
  font-size: 35px;
}

.main-user-select .MuiPaper-elevation {
  top: 65px !important;
}

.sidebar-logo {
  width: 50px;
  height: 46px;
  object-fit: contain;
  margin: auto;
}

.main-sidebar {
  margin: 0px 0 15px;
  justify-content: space-between !important;
  height: 58px;
}

/* header end */
.none-text-left .bsetu-datail {
  display: none;
}

.bsetu-datail-res {
  display: none;
}

/* card css */
.card-main {
  margin: 15px 20px;
  border-radius: 25px;
}

/* Sign up */

.bsetu-datail {
  max-width: 565px;
  margin: 15px 0 15px;
}

.bsetu-datail-para {
  font-family: "Poppins-Regular";
  color: #ffffff;
  line-height: 23px;
  font-size: 13px;
  text-align: center;
}

.signup-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(../images/login-bg.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.submit-btn {
  background-color: #074369;
  color: #fff;
  min-width: 160px;
  min-height: 40px;
  font-size: 15px;
  border-color: transparent;
  border-radius: 5px;
  font-family: "Poppins-Regular";
  cursor: pointer;
}

.link-decorations {
  text-decoration: none !important;
}

.term-privacy {
  text-align: center;
}

.register-both-check .register-check {
  padding: 0 0;
  height: 36px;
  min-width: 120px;
}

/* .register-both-check .register-check span {
  padding: 0 0 0 5px;
} */

/* Company Type page  start*/
.companytype-chechbox {
  margin-left: 10px;
}

.comtype-textarea {
  margin: 8px 0px 0px 0px ;
}

/* Company Type page end */

/* verification-code */
.right-detail h6 {
  margin: 0 0 30px;
  font-family: "Poppins-Regular";
  color: #464646;
  font-size: 20px;
  line-height: 25px;
}

.min-timer {
  display: flex;
  /* margin-left: 200px;
    margin-top: 15px; */
  margin-top: 30px;
  text-align: center;
  justify-content: center;
}

/* create password */
.set-pw {
  margin-bottom: 20px !important;
}

.sbt-btn-btn {
  margin-top: 30px;
}

/* profile css */
.profile-ex .expertise {
  width: 50%;
}

.profile-ex .expertise .expertise-field {
  width: 100%;
}

.profile-ex .expertise:nth-child(1) {
  margin-right: 20px;
}

.user-logo {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  object-fit: contain;
}

.profile-pic {
  width: 55px;
  height: 55px;
  border-radius: 50px;
  background: #cbcbcbcc;
  padding: 5px;
}

.profile-picture-details {
  display: flex;
  align-items: center;
}

.profile-details {
  margin: 0 0 0 20px;
  line-height: 1;
}

/* setting dropdown*/
.setting-dropdown .profile-setting-icon {
  height: 13px;
}

.dropdown-link {
  text-decoration: none;
  color: #074267;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 3px;
}

.dropdown-link p {
  font-size: 14px;
  font-family: "Poppins-Regular";
}

.setting-main {
  /* height: 24px; */
  box-shadow: none !important;
  margin: 0 0 10px 0 !important;
}

.setting-main:before {
  content: none;
  display: none;
}

.setting-main .MuiAccordionSummary-gutters {
  height: 24px;
  min-height: 24px !important;
  margin-left: 7px;
}

.setting-main .setting-sub .MuiAccordionSummary-contentGutters {
  margin: 0 !important;
}

.setting-sub .setting-main-data {
  color: #074267;
  font-size: 16px;
  font-family: "Poppins-Regular";
  min-width: 127px;
}

.setting-sub .MuiAccordionSummary-expandIconWrapper svg {
  color: #074267;
}

.signup-admin {
  position: inherit;
  width: 100%;
  max-width: 400px;
  max-width: 37%;
}

.signup-admin-main {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.admin-signup-details {
  padding: 0 40px;
  width: 100%;
}

.admin-submit-btn {
  background-color: #074369;
  color: #fff;
  min-width: 200px;
  min-height: 40px;
  font-size: 15px;
  border-color: transparent;
  border-radius: 5px;
  margin-top: 15px;
  font-family: "Poppins-Regular";
}

.set-login-right {
  text-align: center;
  /* top: 50%;
    transform: translateY(-50%); */
  margin: 10px 0px;
}

/* .set-reg-box {
  display: none !important;
} */

/* / for password eye in internet expoler/ */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* / for password eye in internet expoler/ */
/* / start file upload img css / */

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  background-color: #ffffff;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  min-width: 17px;
  padding: 0px;
  position: absolute;
  right: 0px;
  bottom: 13px;
  box-shadow: 0px 3px 6px #00000029;
}

.file-upload-btn-main {
  position: relative;
  max-width: max-content;
  background: transparent;
  border-radius: 50px;
}

.update-right {
  padding: 15px 20px;
}

.upload-profile-img {
  height: 70px;
  width: 70px;
  object-fit: contain;
  padding: 5px;
  display: flex;
}

.upload-icon-img {
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.file-upload-btn-main label,
.file-upload-btn-main label:hover {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  display: inline-block;
  width: 17px;
  min-width: 17px;
  height: 17px;
  background: #074369;
  border-radius: 50%;
}

.file-upload-btn-main label svg,
.file-upload-btn-main label svg:hover {
  color: #c3c3c3;
  font-size: 17px;
}

/* / end file upload img css / */
/* job list */
.job-search-select {
  display: flex;
}

.job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  /* max-width: 375px; */
}

.list .MuiInputBase-colorPrimary input {
  min-width: 500px;
  flex: 0;
}

.search .MuiInputBase-colorPrimary input {
  min-width: 500px;
  flex: 0;
}

.search .css-1orzy9s,
.search .css-1orzy9s:hover {
  background: #e3e3e3;
}

.list .css-1orzy9s,
.list .css-1orzy9s:hover {
  background: #e3e3e3;
}

.list .css-1orzy9s {
  margin-left: -5px;
  min-height: 40px;
  border-radius: 12px;
  background: #e3e3e3;
}

.search .css-1orzy9s {
  margin-left: -5px;
  min-height: 40px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}

.job-select {
  padding-bottom: 10px;
  padding-left: 17px;
}

.sub-job-select {
  margin: 0;
}

.sub-job-select .MuiInputBase-formControl {
  height: 40px;
}

.sub-job-select .MuiInputBase-formControl .MuiSelect-outlined {
  padding: 8px 10px;
}

.sub-job-select .MuiInputBase-colorPrimary fieldset {
  border: none;
}

.sub-job-select .job-placeholder,
.main-job-select {
  font-family: "Poppins-Regular";
  font-style: normal;
}

.job-select .sub-job-select {
  margin: 0;
  background: #e3e3e3;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}

.heading {
  padding-bottom: 10px;
  padding-left: 17px;
}

.sub-heading {
  margin: 0;
}

.sub-heading .MuiInputBase-formControl {
  height: 40px;
}

.sub-heading .MuiInputBase-formControl .MuiSelect-outlined {
  padding: 8px 10px;
}

.sub-heading .MuiInputBase-colorPrimary fieldset {
  border: none;
}

.sub-heading .job-placeholder,
.main-heading {
  font-family: "Poppins-Regular";
  font-style: normal;
}

.static-search button,
.static-search button:hover {
  background-color: #074267;
  color: #fff;
  margin-bottom: 12px;
}

.job-image {
  width: 300px;
  height: 210px;
  margin-right: 15px;
}

.job-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.job-discription,
.job-save-icon {
  align-self: flex-start;
}

.job-discription {
  flex: 1;
  flex-wrap: wrap;
  margin: 10px 0px;
}

.job-discription p {
  font-family: "Poppins-Regular";
  /* word-break: break-all; */
}

.job-discription p:nth-child(1) {
  font-family: "Poppins-Bold";
}

.job-discription p:nth-child(2) {
  font-family: "Poppins-Regular";
  color: #a8a8a8;
}

.check-box-bookmark {
  color: #074267 !important;
}

.loader-icon {
  margin-top: 190px;
  margin-left: 530px;
  color: #074267 !important;
}

/* / start notification page css/ */
.notification-title {
  text-align: center;
  font-size: 2rem !important;
}

/* / end notification page css/ */

/* settings */

.setings {
  display: flex;
  margin-top: 10px;
  margin-left: 22px;
}

.setings svg {
  margin: 0;
}

.setting-sub {
  max-width: 185px;
}

/* changepassword page  */

.cpass-heading {
  font-family: "Poppins-Bold";
  font-size: 25px;
  color: rgb(7 67 105);
  margin: 15px 0;
  border-bottom: 1px solid;
}

.cpass-content .mail-input {
  width: 100%;
}

.change-pw-btn {
  background-color: #074369;
  color: #fff;
  min-width: 160px;
  min-height: 40px;
  font-size: 15px;
  border-color: transparent;
  border-radius: 5px;
  font-family: "Poppins-Regular";
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 0;
  margin-top: 10px;
}

.cpass-content {
  margin-top: 20px;
  padding-left: 10px;
}

/* contact us */
.contact-no-title {
  display: flex;
}

.contact-no-title p {
  margin: 0 12px;
}

.contact-email-title P {
  margin: 0 12px;
}

.contact-text {
  font-family: "Poppins-Bold" !important;
}

.contact-numbers {
  display: flex;
  margin: 10px 0;
}

.contact-email {
  margin: 15px 0;
}

.contact-email p {
  font-family: "Poppins-Regular";
  color: #074369;
}

.contact-numbers p {
  width: 25%;
  font-family: "Poppins-Regular";
}

.contact-numbers .pcolor {
  color: #074369;
}

.contact-field {
  display: flex;
  margin: 15px 0px;
}

.c-field {
  margin-right: 30px;
}

.c-field img {
  height: 35px;
}

.contact-email-title {
  display: flex;
}

.contact-field-title {
  display: flex;
}

.contact-field-title p {
  margin: 0 11px;
}

.title {
  text-align: start;
  font-size: 25px;
  color: #074369;
  font-family: "Poppins-Regular";
}

/* add Button for jobwork*/
.addwork-select {
  width: 100%;
}

.job-work-modal .ant-modal-content .ant-modal-footer {
  display: none;
}

.ant-modal-content {
  border-radius: 0% !important;
}

.job-work-modal .mail-input {
  margin: 0;
  margin-top: 20px;
}

.jobwork.mail-input {
  margin-top: 15px;
}

.addwork-select .addwork-select-placeholder,
.main-job-select {
  font-family: "Poppins-Regular";
  font-style: normal;
}

.select-list-jobwork {
  margin-top: 20px !important;
}

.cascader-jobwork .ant-select-selector {
  height: 42;
  min-height: 42px;
}

.addwork-select .MuiInputBase-formControl {
  height: 40px;
}

.cascader-jobwork {
  width: 100%;
}

/* :where(.css-dev-only-do-not-override-ixblex).ant-select .ant-select-arrow {
  top: 65% !important;
} */
/* :where(.css-dev-only-do-not-override-ixblex).ant-select .ant-select-clear {
  top: 65% !important;
} */
.margin-modal {
  margin: 10px 10px;
}

/* inquiry */
.inq-title {
  border-bottom: 1px solid black;
}

.inq-description {
  margin-bottom: 10px;
}

.inq-description .inq-description-area {
  border-color: rgba(0, 0, 0, 0.23);
  padding: 15px;
  width: 100%;
  resize: none;
  font-family: "Poppins-Regular";
  font-size: 15px;
  border-radius: 4px;
}

.inq-description-area::placeholder {
  font-family: "Poppins-Regular";
  font-size: 15px;
}

.model-same-input {
  width: 100% !important;
}

.inquiry-btn {
  margin-top: 10px;
  width: 150px;
  display: flex;
  justify-content: space-between;
}

.inquiry-btn button,
.inquiry-btn button:hover {
  background-color: #074267;
  color: #fff;
}

.Update-icon-delete-icon {
  color: #074267 !important;
  /* position: absolute; */
  align-self: flex-start;
  margin-top: 9px;
  display: flex;
  cursor: pointer;
}

.delete-icon {
  cursor: pointer;
  margin-right: 8px;
}

.change-pw-model {
  max-height: calc(100vh - 100px);
  overflow: auto;
  border: none !important;
  background: #FFFFFF;
  width: 600px;
  border-radius: 10px;
}
.portfolio-same {
  margin-top: 0;
}

.left-login-img-respo {
  display: none;
}

/*/ Privacy-policy start /*/

.inner-banner-wrap {
  padding-bottom: 20px;
}

.inner-baner-container {
  background-color: #074267;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 70px 0 70px;
  text-align: center;
  background-image: url(https://web30india.com/wp-content/uploads/2022/05/banner-pattern1-1.png);
}

.privacy-container {
  max-width: 1330px;
  padding-left: 30px;
  padding-right: 15px;
}

.inner-baner-container .inner-banner-content {
  position: relative;
  color: #ffffff;
}

.inner-baner-container .inner-title {
  color: #ffffff;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 0;
  font-size: 60px;
}

.align-items-center {
  align-items: center !important;
}

.privacy-policy-data {
  flex: 0 0 auto;
  width: 100%;
}

.privacy-policy-data p {
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  margin: 0 0 15px;
  word-wrap: break-word;
  font-weight: 500;
  color: #333;
}

.privacy-policy-data h3 {
  font-size: 25px;
  font-weight: 700;
  color: #223645;
  font-family: "Montserrat", sans-serif;
}

.privacy-policy-data a {
  color: #0085ba;
  text-decoration: none;
}

/*/ Privacy-policy end /*/

/*/ terms and condition start /*/
.terms-and-condition-data {
  flex: 0 0 auto;
  width: 100%;
}

.terms-and-condition-data p {
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  margin: 0 0 15px;
  word-wrap: break-word;
  font-weight: 500;
  color: #333;
}

.terms-and-condition-data h3 {
  font-size: 25px;
  font-weight: 700;
  color: #223645;
  font-family: "Montserrat", sans-serif;
}

.heading-design-terms {
  font-size: 22px;
  font-weight: 600;
  color: #223645;
  font-family: "Montserrat", sans-serif;
}

.terms-and-condition-data a {
  color: #0085ba;
  text-decoration: none;
}

.terms-container {
  max-width: 1330px;
  padding-left: 30px;
  padding-right: 15px;
}

/*/ terms and condition end /*/
.otp-input-box .MuiInputBase-colorPrimary {
  width: 100%;
  max-width: 65px;
  height: 43px;
}

.otp-input-box input,
.otp-input-box input:focus-visible  {
  border: none;
  border-bottom: 1px solid #8f8f8f;
  border-radius: 0;
  border-color: #8f8f8f;
  width: 60px !important;
  height: 60px !important;
  outline: none;
}
.otp-input-box {
  display: flex;
  justify-content: center;
}
.find-job-img {
  height: 300px;
  width: 100%;
  max-width: 350px;
  margin: 30px auto;
  display: flex;
}

.otp-input-box {
  display: flex;
  justify-content: center;
}

.find-job-img {
  height: 300px;
  width: 100%;
  max-width: 350px;
  margin: 30px auto;
  display: flex;
}

.remove-btn {
  position: absolute;
  right: -42px;
}

.personal-ex {
  position: relative;
}

.personal-inputs-main {
  align-items: center;
  display: flex;
}

.companytype-chechbox .MuiCheckbox-root {
  padding: 0px;
  margin-right: 10px;
}

.companytype-chechbox .MuiFormControlLabel-root {
  margin-bottom: 10px;
}
.companytype-chechbox .MuiFormControlLabel-root:first-child{
  margin-right: 40px;
}
.companytype-chechbox .MuiFormControlLabel-label {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Poppins-Regular';
}
.personal-type .work-as-titles {
  font-size: 17px;
  line-height: 20px;
  margin: 10px 0px 20px;
  font-family: 'Poppins-semibold';
  color: #074369;
}
.companytype-chechbox .MuiFormControlLabel-root {
  margin-bottom: 10px;
}
.personal-type .work-as-titles {
  font-size: 17px;
  line-height: 20px;
  margin: 10px 0px 20px;
  font-family: "Poppins-semibold";
}

.profile-page-upload .upload-resum-text {
  font-size: 17px;
  line-height: 20px;
  font-family: "Poppins-semibold";
  margin-bottom: 20px;
  color: #074369;
}

.condition-span {
  font-family: "Poppins-Regular";
  font-size: 13px;
  line-height: 26px;
}

.condition-main .link-decorations {
  font-family: 'Poppins-Medium';
}
.condition-main .link-decorations {
  font-family: "Poppins-Medium";
  font-size: 13px;
  line-height: 26px;
  color: #074369;
}

.have-an-text {
  font-family: 'Poppins-Regular';
  font-size: 13px;
  line-height: 26px;
}

.right-login-btn .link-decorations {
  color: #074369;
  font-family: "Poppins-Medium";
  font-size: 13px;
  line-height: 26px;
}


.login-mobile-bg {
  display: none;
}

.login-mobile-bg .page-heading {
  display: none;
}



.login-main {
  display: flex;
  position: relative;
  width: 100%;
}

.login-left-main {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
}

.login-right-main {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
}

.login-details-main {
  background-image: url(../images/login-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-logo {
  height: 120px;
  width: 120px;
  object-fit: contain;
}

.login-inner-main {
  height: 100%;
  position: absolute;
  bottom: 0px;
  padding: 60px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
  width: 50%;
  background-image: linear-gradient(0deg, #00000099, #0000007d, transparent);
}

.login-inner-main .login-titles {
  font-size: 30px;
  line-height: 35px;
  font-family: "Poppins-semibold";
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.login-inner-main .login-para {
  font-size: 17px;
  line-height: 25px;
  font-family: "Poppins-Regular";
  color: #fff;
}

.auth-inner-main {
  height: 100vh;
  overflow: scroll;
}

/* width */
.auth-inner-main::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.auth-inner-main::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
.auth-inner-main::-webkit-scrollbar-thumb {
  background: #8888884f;
}


/* Handle on hover */
.auth-inner-main::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.auth-logo-box{
  display: flex;
  margin: 0px auto 30px auto;
  justify-content: center;
}
.auth-inner-main .auth-page-titles{
  font-size: 30px;
  line-height: 35px;
  font-family: "Poppins-semibold";
  color: #074369;
  text-align: center;
  margin-bottom: 30px;
}

.personal-type .auth-checkbox-flex {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: initial;
}
.auth-submit-btn-main{
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.grid-column{
  padding-right: 10px;
  padding-left: 10px;
}
.grid-row {
  margin-right: -10px;
  margin-left: -10px;
}

.auth-input-main .form-control input{
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  color: #666666;
  height: auto;
  padding: 0 15px;
}
.cust-grid-main{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
}
 .field-signup{
margin-left: 20px !important;  
}
.field-signup-disabled{
  display: none !important;
}
.others-class-exp{
  display: flex;
}
.cust-grid-column-5 {
  width: 49%;
  max-width: 49%;
  flex: 0 0 49%;
}
.cust-grid-column-1 {
  /* width: 5%;
  max-width: 5%;
  flex: 0 0 5%; */
  margin-left: 24px;
}
.personal-inputs-main .personal-inputs-box:first-child {
  margin-right: 10px;
}
.login-mobile-bg {
  display: none;
}
.login-mobile-bg .page-heading {
  display: none;
}
.otp-screen .right-login-btn {
  text-align: center;
}

.auth-submit-btn-main .MuiCircularProgress-root {
  width: 35px !important;
  color: #074369;
  height: 35px !important;
  margin-left: 20px;
}

.auth-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding:10px 50px 50px 50px;
}
.otp-inner-main .otp-para{
  text-align: center;
}
.primary-btn-main .primary-btn , .primary-btn-main .primary-btn:hover{
  background-color: #074369;
  color: #fff;
  min-width: 170px;
  min-height: 43px;
  font-size: 15px;
  border-color: transparent;
  border-radius: 5px;
  font-family: "Poppins-Regular";
  cursor: pointer;
}

.comtype-textarea textarea{
  width: 100%;
  margin: 0px 0px 15px;
  padding: 15px;
  font-size: 15px;
  font-family: "Poppins-Regular";
  line-height: 20px;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  resize: none;
}
.primary-btn-main .company-type-btn{
    margin: 10px 0px;
}
.company-details-main-btn{
  margin: 10px 0px;
}
.personal-add-more-btn{
  margin-top: 35px;
  margin-bottom: 10px;
}
.personal-btn-main{
  margin-top: 25px;
}
.profile-page-upload .auth-sub-titles {
  font-size: 17px;
  line-height: 20px;
  font-family: 'Poppins-Regular';
  margin-bottom: 20px;
}
.personal-continue-btn {
  margin-top: 18px;
}
.email-verfy-btn-main .primary-btn{
  margin-top: 20px;
}
.company-profile-btn-main{
  margin-top: 20px;
}
.primary-btn-main .company-type-btn{
    margin-top: 20px;
}
.create-pass-btn-main .primary-btn {
  margin-top: 10px;
}
.change-pw-model .modal-title{
  font-size: 30px;
  line-height: 35px;
  font-family: "Poppins-semibold";
  color: #074369;
  margin-bottom: 30px;
}


.change-pw-model::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.change-pw-model::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
.change-pw-model::-webkit-scrollbar-thumb {
  background: #8888884f;
}


/* Handle on hover */
.change-pw-model::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.product-upload-btn {
  margin: 25px 0px 0px;
}

.remove-center-btn{
  display: flex;
  align-items: center;
  justify-content: center;
}

.privacy-policy-data ul {
  padding-left: 16px;
}

/* / width / */
.ant-cascader-menu::-webkit-scrollbar  {
  width: 2px;
;
}

/* / Track / */
.ant-cascader-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* / Handle / */
.ant-cascader-menu::-webkit-scrollbar-thumb {
  background: #888;
}

/* / Handle on hover / */
.ant-cascader-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.set-select .ant-cascader-dropdown .ant-cascader-menu ,.set-select:where(.css-dev-only-do-not-override-1km3mtt).ant-cascader-dropdown .ant-cascader-menu{
    flex-grow: 1;
    min-width: 111px;
    height: 200px !important;
    margin: 0;
    padding: 4px;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.auth-input-main2 .ant-cascader  .ant-select-selector span .ant-select-selection-placeholder {
  width: 100%;
  font-size: 15px !important;
  line-height: 20px;
  font-family: "Poppins-Regular";
  color: #666666 !important;
  height: auto;
  padding: 0 15px;
  background-color: #ffffff!important;
}
/* :where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
:where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):active .ant-select-selector,
:where(.css-dev-only-do-not-override-1km3mtt).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):focus .ant-select-selector{
  border-color: rgba(0, 0, 0, 0.23) !important;
  box-shadow: none;
} */
:where(.css-dev-only-do-not-override-1km3mtt).ant-select .ant-select-selection-placeholder {
  width: 100%;
    font-size: 15px !important;
    line-height: 40px !important;
    font-family: "Poppins-Regular";
    color: #666666 !important;
    height: auto;
    padding: 0 5px !important;
    background-color: #ffffff!important;
}
:where(.css-dev-only-do-not-override-1km3mtt).ant-select-single .ant-select-selector .ant-select-selection-item, :where(.css-dev-only-do-not-override-1km3mtt).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 40px !important;
  transition: all 0.3s;
}


.set-select .ant-select-selection-search-input {
  height: 39px !IMPORTANT;
}

.set-select span.ant-select-selection-placeholder{
  line-height: 41px !important;
   color: #666666 !important;
   font-size: 16px;
  background: white !important;
}
.set-select span.ant-select-selection-item{
  line-height: 41px !important;
   color: #666666 !important;
}

.set-select span.ant-select-selection-item {
  font-size: 16px;
  line-height: 40px !important;
}

.set-select span.ant-select-selection-placeholder,
.auth-input-main2 .ant-select-selector{
    /* background:white !important;
    padding-right: 28px !important; */
    background: #fff!important;
    padding-right: 28px!important;
        border-color: #0000003b !important;
    border-radius: 4px !important;
 
}
.auth-input-main2 .ant-select-selector .ant-select-selection-item{
  align-items: center!important;
    color: #666 !important;
    display: flex!important;
    font-size: 16px;
    height: auto;
    line-height: 20px;
    padding: 0 5px!important;
    width: 100%;
  }
  .auth-input-main2 .ant-select-selection-placeholder {
    /* display: flex;
    align-items: center;
    color: #626262;
    background:transparent !important; */
    align-items: center;
    background: transparent!important;
    color: #666;
    font-size: 16px;
    display: flex;
}
.auth-input-main3 .ant-select-selector, .set-select span.ant-select-selection-placeholder {
 
  margin-top: 3px !important;
}
.auth-input-main2 .ant-select:hover  .ant-select-selector {
  border-color:rgb(33 33 33) !important;
  box-shadow: none !important;
}

/* expertise modal input css start */
.btn-expertise-list .expertise-btn-input ,.btn-expertise-list .expertise-btn-input:hover{
  background-color: #f5f5f5;
  height: 43px;
  border-radius: 4px;
  text-transform: capitalize;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  color: #666666;
  padding: 0 15px;
  justify-content: start;
  border: 1px solid #c4c4c4 !important;
  color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    cursor: text;
}

.expertise-modal-main .modal-inner-list{
  background-color: #fff;
  border-radius: 25px;
  max-width: 551px;
  width: 100%;
}

.expertise-modal-main .modal-inner-list .modal-body-pd{
  padding: 17px 25px 10px 25px;
}

.expertise-modal-main .select-title-experties{
  font-size: 20px;
  font-family: "Poppins-semibold";
  line-height: 30px;
  margin-bottom: 17.5px;
}

.expertise-btn-listing .expertise-btn-list-inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
  padding-top: 20.5px;
}
.expertise-btn-listing .expertise-btn-list-inner .btn-list-main-exper {
  padding: 0px 7.5px;
  margin-bottom: 15px;
}
.expertise-btn-listing .expertise-btn-list-inner .btn-list-main-exper .expertise-btn-box {
  padding: 7px 20px;
  border-radius: 6px;
  background-color: #ECECEC;
  color: #000000;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Poppins-Regular';
}
.expertise-btn-listing {
  border-top: 1px solid #ECECEC;
}
.expertise-btn-listing .expertise-btn-list-inner .btn-list-main-exper .expertise-active-hide-show{
  background-color: #074369;
  color: #fff;
}
.main-expertise-details .display-none-expertise{
  display: none;
}
.expertise-active-hide-show + .display-none-expertise{
  display: block;
}
.btn-expertise-list .expertise-btn-input span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-expertise-details {
  max-height: 70vh;
  overflow: auto;
  padding: 0 10px;
  margin: 0 -10px;
}
.main-expertise-details::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.main-expertise-details::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.main-expertise-details::-webkit-scrollbar-thumb {
  background: #888; 
    border-radius:10px;
}
.main-expertise-details::-webkit-scrollbar-thumb:hover {  
  background: #555; 
} 
/* expertise modal input css end */